import { useMemo, useLayoutEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import articles from "./articles";
const BlogDetail = () => {

  const { id } = useParams();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const article = useMemo(() => {
    return articles.find((item) => item.id === id);
  }, [id]);

  return article ? (
    <article className="article-page" style={{backgroundColor: article.backgroundColor}}>
      <div className="article-info">
        <h1>{article.title}</h1>
        <span>{article.publishAt}</span>
        <img src={article.image} alt={article.title} />
      </div>
      <div className="article-content">{article.content}</div>
    </article>
  ) : (
    <Navigate replace to="/blog" />
  );
}

export default BlogDetail;