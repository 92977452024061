export const isSupportWebp = () => {
  try {
    const img = document.createElement("canvas");
    const imgData = img.toDataURL("image/webp", 0.5);
    return imgData.indexOf("data:image/webp") === 0;
  } catch (err) {
    return false;
  }
};

const isSupport = isSupportWebp();

export function getImageUrl(
  url: string | undefined | null,
  styleName?: string
): string {
  if (!url) {
    return "";
  }
  if (!isSupport) {
    return url;
  }
  if (url.startsWith("http://")) {
    url = url.replace("http://", "https://");
  }
  // if image url has any query params, assume that the image will override the oss params
  if (
    /(beyondstyle\.us|bybieyang\.com|bieyangapp\.com)\/.*\.(png|jpg|jpeg)$/i.test(
      url
    )
  ) {
    // by default s1 -> webp + 90q
    return url + `!${styleName || "s1"}`;
  }
  return url;
}


const accentMap = {
  a: /[àáâäãåæ]/g,
  c: /[ç]/g,
  e: /[èéêëē]/g,
  i: /[ìíîï]/g,
  d: /[ð]/g,
  n: /[ñ]/g,
  o: /[òóôöõøœ]/g,
  u: /[ùúûü]/g,
  y: /[ýÿ]/g,
  A: /[ÀÁÂÄÃÅÆ]/g,
  C: /[Ç]/g,
  E: /[ÈÉÊËĒЁ]/g,
  I: /[ÌÍÎÏ]/g,
  D: /[Ð]/g,
  N: /[Ñ]/g,
  O: /[ÒÓÔÖÕØŒ]/g,
  U: /[ÙÚÛÜŪ]/g,
  Y: /[ÝŸ]/g,
};

function replaceSpecialCharacters(text: string) {
  for (const char in accentMap) {
    if (accentMap.hasOwnProperty(char)) {
      text = text.replace(accentMap[char as keyof typeof accentMap], char);
    }
  }
  return text;
}

export function normalizeTerm(
  name: string | null | undefined,
  options: any = {}
): string {
  const { keepCase, keepDot } = options || {};
  if (!name) {
    return "";
  }
  try {
    name = name.replace(
      /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
      ""
    );
    name = name.replace(
      /[`~!®@#$%^&*()_|+\-=?;:'’",<>\{\}\[\]\\\/™φ°•·]/gi,
      ""
    );
    if (!keepDot) {
      name = name.replace(/[.]/gi, "");
    }
    name = replaceSpecialCharacters(name);
    name = name.replace(/[\s\t\n]+/gi, " ");
    name = name.trim();
    name = name.replace(/[\s\t\n]/gi, "-");
  } catch (e) {}
  return encodeURIComponent(keepCase ? name : name.toLowerCase());
}