import { useRef, useEffect } from "react";
import { TextLine, Button, Image } from '@/components'
import emitter from "@/event";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollIntoView } from "@/utils";
import "./style.scss";
import "./style.mobile.scss";
import AIImage from '@/assets/images/ai-module.jpg'
const AIShoppingAgent = () => {
  const navigate = useNavigate()
   const el = useRef<HTMLDivElement>(null);
   const location = useLocation();
   useEffect(() => {
     const scrollHandler = () => {
       scrollIntoView(el.current as HTMLDivElement);
     };
     emitter.on("SCROLL_TO_AI_SHOPPING_AGENT", scrollHandler);
     return () => {
       emitter.on("SCROLL_TO_AI_SHOPPING_AGENT", scrollHandler);
     };
   }, []);

   useEffect(() => {
     if (location.pathname === "/ai-shopping-agent") {
       setTimeout(() => {
         scrollIntoView(el.current as HTMLDivElement);
       }, 20);
     }
   }, [location.pathname]);
  return (
    <div className="ai-shopping" ref={el}>
      <div className="ai-title">
        If you are <br /> <TextLine>AI Shopping Agents</TextLine>
      </div>
      <div className="ai-subtitle">
        The Age of AI Agents is here. AI shopping agents are evolving fast.
      </div>
      <div className="ai-info">
        <div className="ai-des">
          The expectations for AI shopping agents or assistants span a broad
          spectrum, from basic tasks to sophisticated operations. CloudStore AI
          has emerged as a comprehensive platform to support these AI shopping
          agents across the entire retail journey - from managing product
          catalogs, order fulfillment, to tracking logistics, and handling
          returns.
        </div>
        <Image className="ai-module" src={AIImage}></Image>
      </div>
      <div className="btn-group">
        <Button
          onClick={() => navigate("/contact-us")}
          className="ai-btn btn-contact"
        >
          Contact Us
        </Button>
        <Button
          onClick={() => navigate("/blog")}
          className="ai-btn btn-blog"
        >
          Blog
        </Button>
      </div>
    </div>
  );
};

export default AIShoppingAgent;
