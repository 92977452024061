import { FC, useState, ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames"
import "./style.scss";
import "./style.mobile.scss"

interface InputProps {
  label?: ReactNode;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string
  error?: boolean
};

const Input: FC<InputProps> = ({
  label,
  value,
  onChange,
  className,
  error,
}) => {
  const [focus, setFocus] = useState(false);
  const [hasValue, setValue] = useState(() => !!value);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(!!e.target.value);
    onChange?.(e);
  };
  return (
    <label
      className={classNames("input-container", className, {
        fill: value || hasValue,
        focus,
        error,
      })}
    >
      <span className="placeholder">{label}</span>
      <input
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          setFocus(false);
          setValue(!!e.target.value);
        }}
        value={value}
        onChange={handleChange}
        type="text"
      />
      <span className="border-line"></span>
    </label>
  );
};

export default Input