import { FC } from "react";
import { RouteObject, useRoutes, Navigate } from 'react-router-dom'
import Home from '@/pages/home'
import Company from "@/pages/company";
import ContactUs from "@/pages/contact-us";
import Blog from "@/pages/blog";
import BlogList from '@/pages/blog/blog-list'
import BlogDetail from '@/pages/blog/blog-detail'

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/e-commerce-marketplace",
        element: <></>,
      },
      {
        path: "/influencer-publisher",
        element: <></>,
      },
      {
        path: "/drop-shipping-merchant",
        element: <></>,
      },
      {
        path: "/ai-shopping-agent",
        element: <></>,
      },
      {
        path: "/merchants-brands",
        element: <></>,
      },
      {
        path: "/brand-launch",
        element: <></>,
      },
    ],
  },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/blog",
    element: <Blog />,
    children: [
      {
        path: "",
        element: <BlogList />,
      },
      {
        path: ":id?",
        element: <BlogDetail />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

const Router: FC = () => {
  return useRoutes(routes);
};

export default Router;

