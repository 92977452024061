import { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Articles from "./articles";
import Image from '@/components/image'
const BlogList = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <section>
      <ul>
        {
          Articles.map((article) => {
            return (
              <li
                className="blog-item"
                key={article.id}
                style={{ backgroundColor: article.backgroundColor }}
              >
                <div className="blog-content">
                  <h2 className="blog-title">{article.title}</h2>
                  <p className="blog-subtitle ellipsis-multiline">
                    {article.subtitle}
                  </p>
                  <Link
                    to={`/blog/${article.id}`}
                    className="blog-action"
                    title="Read More"
                    role="button"
                  >
                    Read More
                  </Link>
                </div>
                <Image className="blog-item-image" src={article.image} />
              </li>
            );
          })
        }
      </ul>
    </section>
  );
}

export default BlogList;