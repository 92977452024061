import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Image } from '@/components'
import { scrollIntoView } from "@/utils"
import classNames from 'classnames'
import emitter from '@/event'
import merchant11 from "@/assets/images/merchant-1-1.png"
import merchant12 from "@/assets/images/merchant-1-2.png"
import merchant13 from "@/assets/images/merchant-1-3.png"
import merchant14 from "@/assets/images/merchant-1-4.png"

import merchant21 from "@/assets/images/merchant-2-1.png";
import merchant22 from "@/assets/images/merchant-2-2.png";
import merchant23 from "@/assets/images/merchant-2-3.png";
import merchant24 from "@/assets/images/merchant-2-4.png";

import merchant31 from "@/assets/images/merchant-3-1.png";
import merchant32 from "@/assets/images/merchant-3-2.png";
import merchant33 from "@/assets/images/merchant-3-3.png";
import merchant34 from "@/assets/images/merchant-3-4.png";
import merchant35 from "@/assets/images/merchant-3-5.png";

import merchant41 from "@/assets/images/merchant-4-1.png";
import merchant42 from "@/assets/images/merchant-4-2.png";
import merchant43 from "@/assets/images/merchant-4-3.png";
import merchant44 from "@/assets/images/merchant-4-4.png";
import merchant45 from "@/assets/images/merchant-4-5.png";

import merchant51 from "@/assets/images/merchant-5-1.png";
import merchant52 from "@/assets/images/merchant-5-2.png";
import merchant53 from "@/assets/images/merchant-5-3.png";
import merchant54 from "@/assets/images/merchant-5-4.png";


import brand11 from "@/assets/images/brand-1-1.png";
import brand12 from "@/assets/images/brand-1-2.png";
import brand13 from "@/assets/images/brand-1-3.png";
import brand14 from "@/assets/images/brand-1-4.png";

import brand21 from "@/assets/images/brand-2-1.png";
import brand22 from "@/assets/images/brand-2-2.png";
import brand23 from "@/assets/images/brand-2-3.png";

import brand31 from "@/assets/images/brand-3-1.png";
import brand32 from "@/assets/images/brand-3-2.png";
import brand33 from "@/assets/images/brand-3-3.png";
import brand34 from "@/assets/images/brand-3-4.png";
import brand35 from "@/assets/images/brand-3-5.png";

import brand41 from "@/assets/images/brand-4-1.png";
import brand42 from "@/assets/images/brand-4-2.png";
import brand43 from "@/assets/images/brand-4-3.png";

import brand51 from "@/assets/images/brand-5-1.png";
import brand52 from "@/assets/images/brand-5-2.png";
import brand53 from "@/assets/images/brand-5-3.png";
import brand54 from "@/assets/images/brand-5-4.png";

import "./style.scss"
import "./style.mobile.scss"

const merchants = [
  [merchant11, merchant12, merchant13, merchant14],
  [merchant21, merchant22, merchant23, merchant24],
  [merchant31, merchant32, merchant33, merchant34, merchant35],
  [merchant41, merchant42, merchant43, merchant44, merchant45],
  [merchant51, merchant52, merchant53, merchant54],
];

const brands = [
  [brand11, brand12, brand13, brand14],
  [brand21, brand22, brand23],
  [brand31, brand32, brand33, brand34, brand35],
  [brand41, brand42, brand43],
  [brand51, brand52, brand53, brand54],
];


const MerchantsBrands = () => {
  const el = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const scrollHandler = () => {
      scrollIntoView(el.current as HTMLDivElement);
    };
    emitter.on("SCROLL_TO_MERCHANT", scrollHandler);
    return () => {
      emitter.on("SCROLL_TO_MERCHANT", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/merchants-brands") {
      setTimeout(() => {
        scrollIntoView(el.current as HTMLDivElement);
      }, 20)
    }
  }, [location.pathname]);

  return (
    <div className="merchants-brands" ref={el}>
      <div className="brand-title title">Brands</div>
      <div className="brand-list">
        {brands.map((item, index) => (
          <div
            key={index}
            className={classNames("brand-row", `brand-row-${index + 1}`)}
          >
            {item.map((link, k) => (
              <Image className="brand-image" src={link} key={k}></Image>
            ))}
          </div>
        ))}
      </div>
      <div className="merchant-title title">Merchants</div>
      <div className="merchant-list">
        {merchants.map((item, index) => (
          <div
            key={index}
            className={classNames("merchant-row", `row-${index + 1}`)}
          >
            {item.map((link, k) => (
              <Image className="merchant-image" src={link} key={k}></Image>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MerchantsBrands;