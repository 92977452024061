import {
  Gallery,
  Data,
  Accessibility,
  Growth,
  Empowerment,
  APIIntegration,
  Fashion,
  GlobalSales,
  API,
  Advantage,
  Marketplace,
  SuccessfulCase,
  Publisher,
  BeyondStyle,
  MerchantsBrands,
  Footer,
  BrandLaunch,
  AIShopping,
} from "@/modules";
const Home = () => {

  return (
    <>
      <Gallery />
      <Data />
      <Accessibility />
      <Growth />
      <Empowerment />
      <APIIntegration />
      <Fashion />
      <GlobalSales />
      <API />
      <Advantage />
      <Marketplace />
      <SuccessfulCase />
      <Publisher />
      <BeyondStyle />
      <AIShopping />
      <MerchantsBrands />
      <BrandLaunch />
      <Footer />
    </>
  );
}

export default Home;