import { Outlet } from "react-router-dom"
import "./style.scss"
import "./style.mobile.scss"

const Blog = () => {

  return (
    <div className="blog-page">
      <Outlet />
    </div>
  );
}

export default Blog;