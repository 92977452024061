import type { ReactNode } from 'react'
import aiShoppingAgent from '@/assets/images/ai-shopping-agent.jpg'
import aiOnlineShopping from '@/assets/images/ai-online-shopping-agents.jpg'
import shoppingExperience from '@/assets/images/shopping-experience.jpg'
import { normalizeTerm } from '@/utils/helper'
export interface Article {
  id: string;
  title: string;
  subtitle: string;
  backgroundColor: string;
  publishAt: string;
  image: string;
  content: ReactNode;
}


const articles: Article[] = [
  {
    title: "AI Shopping Agent in 2025",
    backgroundColor: "#DCFF9B",
    subtitle:
      "The Age of AI Agents is here. AI shopping agents are evolving fast. Perplexity, Amazon (Rufus), and many other players are developing AI shopping agents or assistants. ",
    publishAt: "Published on Jan 15, 2025",
    image: aiShoppingAgent,
    content: (
      <>
        <p>
          The Age of AI Agents is here. AI shopping agents are evolving fast.
          Perplexity, Amazon (Rufus), and many other players are developing AI
          shopping agents or assistants.
        </p>
        <p>
          As consumer-facing agents, AI shopping agents or travel agents are
          more complex than enterprise-facing agents because of heterogeneity of
          consumer applications and complexity of understanding consumers. In
          order to prepare for a better future for the AI shopping agent world,
          we encourage ecommerce merchants to set a standard and open protocol
          about ecommerce infrastructure such as product catalog and order
          fulfillment.  It will accelerate AI shopping agent progress and
          improve agent accuracy. 
        </p>
        <p>
          The expectations for AI shopping agents or assistants span a broad
          spectrum, from basic tasks to sophisticated operations. CloudStore AI
          (
          <a target="_blank" href="https://www.nubestore.ai/" rel="noreferrer">
            https://www.nubestore.ai/
          </a>
          ) of BorderX Lab has emerged as a comprehensive tool to support these
          AI shopping agents across the entire shopping journey - from managing
          product catalogs, fulfilling orders, to tracking logistics, and
          handling returns.
        </p>
        <p>
          Ecommerce needs a lot of plumbing work, order fulfillment, logistics
          tracking, and return processing. Humans are definitely in the loop now
          for AI shopping agents. The work distribution of humans and AI is
          evolving. Eventually AI shopping agents can have the same skills as
          humans.
        </p>
        <p>Now is an exciting time to build AI shopping agents.</p>
      </>
    ),
  },
  {
    title: "AI Online Shopping Agents: The Future of E-commerce",
    backgroundColor: "#FAFAFA",
    subtitle:
      "The rapid advancements in AI, particularly with Large Language Models (LLMs), have made the development of AI Online Shopping Agents (AIOSAs) a real possibility. While online...",
    publishAt: "Published on Jan 14, 2025",
    image: aiOnlineShopping,
    content: (
      <>
        <p>
          The rapid advancements in AI, particularly with Large Language Models
          (LLMs), have made the development of AI Online Shopping Agents
          (AIOSAs) a real possibility. While online shopping is a common task
          for many, a successful purchase often requires significant time and
          effort. For instance, finding the perfect desk for my 8-year-old
          daughter on Amazon took nearly an hour. I had to compare multiple
          products, understand their features, read customer reviews, and select
          the right size and color. Even after receiving the item, there might
          be additional tasks if I'm unsatisfied with the purchase.
        </p>
        <p>
          In the foreseeable future, AI Online Shopping Agents can assist us in
          completing successful online shopping trips and save us time, energy,
          and money. For those who struggle with internet use, such as the
          elderly, the benefits would be even more pronounced.
        </p>
        <p>
          However, developing an AI Online Shopping Agent is not a trivial task.
          It presents several challenges:
        </p>
        <ul>
          <li>
            <strong>Interacting with the Internet:</strong> AI must be able to
            interact with the internet as humans do. While recent advancements
            in AI agents, like Anthropic's computer use, have made strides,
            there's still room for improvement.
          </li>
          <li>
            <strong>Domain Knowledge:</strong> AI needs to possess
            domain-specific knowledge about shopping. It's not inherently
            knowledgeable about every aspect of shopping. Each step in the
            shopping process requires prompts to guide the AI, telling it which
            websites to visit, tools to use, and so on.
          </li>
          <li>
            <strong>Human Interaction:</strong> AI must effectively interact
            with humans, following human guidance and making decisions under
            human supervision.
          </li>
          <li>
            <strong>Safety and Security:</strong> With AI hallucinations still
            being an issue, ensuring the accuracy and security of AI-driven
            actions is crucial.
          </li>
        </ul>
        <p>
          Given these challenges, we believe the development of AI Online
          Shopping Agents will be a gradual process. In the early stages,
          critical steps like placing orders may still require human-AI
          collaboration.
        </p>
        <p>
          At BorderX Lab, we're actively researching and developing AI Online
          Shopping Agents. With a decade of experience in e-commerce and RPA
          services, we're confident in the potential of AI Online Shopping
          Agents and believe we're well-positioned to succeed. For example, our
          cloud store product (
          <a target="_blank" href="https://www.nubestore.ai/" rel="noreferrer">
            https://www.nubestore.ai/
          </a>
          ) leverages RPA to automate order placement on major European and
          American fashion e-commerce sites with a success rate of 95%. This
          service can seamlessly integrate with AI to create AI Online Shopping
          Agents specifically for fashion e-commerce.
        </p>
      </>
    ),
  },
  {
    title:
      "AI Shopping Agents: Unlocking the Secrets of a Perfect Shopping Experience",
    backgroundColor: "#A3F4FF",
    subtitle:
      "Imagine a future where you wake up, and your AI shopping agent, integrated with your smart home system, has already analyzed the data from your smart fitness tracker. It knows you've been ...",
    publishAt: "Published on Jan 13, 2025",
    image: shoppingExperience,
    content: (
      <>
        <p>
          Imagine a future where you wake up, and your AI shopping agent,
          integrated with your smart home system, has already analyzed the data
          from your smart fitness tracker. It knows you've been pushing yourself
          hard at the gym, and as you enter the kitchen, a holographic display
          appears, showing you the latest high - performance sports gear that's
          perfect for your workouts. When you're getting ready for a virtual
          business meeting, the AI, having studied your past meeting outfits and
          the nature of the event, suggests a stylish, professional ensemble
          that can be ordered and delivered within hours. As you commute, the
          agent notifies you of a limited - edition art piece that matches your
          home decor preferences, and with a simple voice command, you can add
          it to your cart.
        </p>
        <p>
          Are these the kind of shopping experiences we all desire? In recent
          years, AI shopping agents have emerged as a revolutionary force within
          the dynamic e - commerce landscape. Giants like Amazon, leveraging the
          power of Alexa. Similarly, Alibaba, with its cutting - edge smart
          shopping assistants, has been leading the charge in the East. Their AI
          can not only understand complex natural language queries but also
          provide astute product recommendations. It can even smoothly complete
          purchases, making the shopping process as seamless as possible.
        </p>
        <p>
          However, this is just the tip of the iceberg. There remains an ocean
          of untapped potential for further development, such as enhancing the
          agents' ability to handle more nuanced emotions in customer requests
          and integrating with emerging technologies like augmented reality for
          a more immersive shopping experience.
        </p>
        <p>
          What sets BorderX Lab's Cloud Store apart from its competitors is
          truly extraordinary. It has established an incredibly robust fashion
          supply chain in both Europe and the United States. For instance, when
          Paris Fashion Week debuts the latest trends, the Cloud Store's AI
          shopping agents can quickly offer customers those exclusive pieces.
          This means fashion enthusiasts can get their hands on the most up - to
          - date and unique fashion items straight from these fashion - forward
          regions.
        </p>
        <p>
          The Cloud Store also houses a colossal database that towers over many
          others in the industry. It doesn't stop at fashion; it encompasses a
          broad range of products, from electronics to home decor. With real -
          time price and inventory updates, the AI agents ensure that customers
          are always in the know. No more disappointment of clicking on a
          product only to find it's out of stock or being charged an incorrect
          price.
        </p>
        <p>
          Moreover, its personalized data analytics are unrivaled. By delving
          deep into customers' shopping habits, preferences, and even style
          choices, the AI agents can offer customized recommendations like no
          other, creating a shopping experience that is uniquely tailored to
          each individual.
        </p>
        <p>
          The Cloud Store will serve as a huge product data repository. For
          instance, when a user searches for a high - end smartphone, the AI
          agent, leveraging the Cloud Store's data, can instantly list all
          available models from different brands, their features like camera
          quality, processing speed, and battery life, along with price
          comparisons across multiple online platforms.
        </p>
        <p>
          Advanced algorithms in the Cloud Store analyze customers' behaviors.
          Suppose a customer often buys fitness equipment. The AI agent can then
          suggest new yoga mats from emerging eco - friendly brands added to the
          Cloud Store, complete with user reviews.
        </p>
        <p>
          Moreover, for cross - border shopping, if a customer in the US wants
          to buy Japanese skincare products, the agent, using the Cloud Store's
          global network, can handle currency conversion, find the best - priced
          products, and sort out shipping details. This makes shopping more
          efficient and globally accessible.
        </p>
      </>
    ),
  },
].map(item => ({
  ...item,
  id: normalizeTerm(item.title)
}));

export default articles;